/* eslint-disable vtex/prefer-early-return */
import axios from 'axios'
import { SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo'
import React, { useEffect, useState } from 'react'
import { gql } from '@faststore/graphql-utils'
import ProductGallery from 'src/components/sections/ProductGallery'
import { useSearchParams } from 'src/hooks/useSearchParams'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import { isNotFoundError } from '@faststore/api'
import { execute } from 'src/server/index'
import '../../styles/pages/product-listing-page.scss'
import '../../styles/pages/departament.scss'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import productPerPage from 'src/utils/productPerPage'
import { FilterContextProvider } from 'src/contexts/FilterContext/filter-context'
import GatsbySeoCustom from 'src/components/GatsbySeoCustom'
import type { PageProps } from 'gatsby'
import type {
  SecondServerCollectionPageQueryQuery,
  SecondCollectionPageQueryQuery,
  SecondCollectionPageQueryQueryVariables,
} from '@generated/graphql'
import RenderCMS from 'src/components/RenderCMS'
import { useCampaignContext } from 'src/contexts/campaign-context'
import type CollectionServerData from 'src/typings/collectionServerData'
import { buildJsonData, getSearchData } from 'src/utils/collectionBuildData'

import storeConfig from '../../../store.config'

type Props = PageProps<
  SecondCollectionPageQueryQuery,
  SecondCollectionPageQueryQueryVariables,
  unknown,
  (SecondServerCollectionPageQueryQuery & CollectionServerData) | null
> & { slug: string }

interface SectionsProps {
  name: string
  data: any
}

async function getCmsData(
  contentType: string,
  documentId: string,
  versionId: string,
  setCmsData: React.Dispatch<React.SetStateAction<SectionsProps[] | undefined>>
) {
  await axios
    .post(
      '/api/getCmsContent',
      {
        contentType,
        documentId,
        versionId,
      },
      {
        headers: {
          Accept: 'application/vnd.vtex.ds.v10+json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
    .then((response) => {
      setCmsData(response?.data?.sections)
    })
    .catch((error) => {
      console.error('Error -> ', error)
    })
}

function Page(props: Props) {
  const {
    serverData,
    data: { cmsHome },
  } = props

  const [beforeSections, setBeforeSections] = useState(
    [] as Array<{ name: string; data: any }>
  )

  const [afterSections, setAfterSections] = useState(
    [] as Array<{ name: string; data: any }>
  )

  const [isLifePage, setIsLifePage] = useState<boolean | undefined>(undefined)
  const [cmsData, setCmsData] = useState([] as SectionsProps[] | undefined)
  const title =
    serverData?.collection?.breadcrumbList?.itemListElement?.[0]?.name ??
    props?.slug?.replace(/[- %20]/g, ' ') ??
    ''

  const description = serverData?.description

  const { width } = useWindowDimensions()

  const ITEM_PER_PAGE_VERIFIED = productPerPage(width)

  const searchParams = useSearchParams(props)

  const defaultFacets = serverData?.collection?.meta?.selectedFacets

  const { base } = searchParams

  const { setCampaignPage } = useCampaignContext()

  setCampaignPage(false)

  const hasCollectionName = title
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .includes('colecao')

  const newCollectionTitle = hasCollectionName ? title : `Coleção ${title}`

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const SearchParams = new URLSearchParams(window?.location?.search)
      const contentType = SearchParams?.get('contentType')
      const documentId = SearchParams?.get('documentId')
      const versionId = SearchParams?.get('versionId')

      setIsLifePage(window?.location?.pathname.includes('life'))

      if (contentType && documentId && versionId) {
        getCmsData(contentType, documentId, versionId, setCmsData)

        return
      }

      setCmsData(serverData?.dataCollection?.data[0]?.sections)
    }
  }, [])

  useEffect(() => {
    const hasCampaignFold = cmsData?.findIndex((e) => e.name === 'CampaignFold')
    const hasAfterGallery = cmsData?.findIndex((e) => e.name === 'AfterGallery')

    if (cmsData && hasCampaignFold === -1 && hasAfterGallery === -1) {
      setBeforeSections(cmsData)

      return
    }

    if (cmsData && hasCampaignFold !== -1) {
      setBeforeSections(cmsData?.filter((e) => e.name !== 'AfterGallery'))

      return
    }

    if (cmsData && hasAfterGallery !== -1) {
      setBeforeSections(cmsData?.slice(0, hasAfterGallery))
      setAfterSections(cmsData?.slice(hasAfterGallery, cmsData?.length))
    }
  }, [cmsData])

  const [insiderLoaded, setInsiderLoaded] = useState(false)

  if (typeof window !== 'undefined' && !insiderLoaded) {
    window.insider_object = window.insider_object || {}
    window.insider_object.page = window.insider_object.page || {}
  }

  useEffect(() => {
    if (window.insider_object.page.type !== 'Category') {
      window.insider_object.page = {
        type: 'Category',
        originalType: 'Category',
      }
      setInsiderLoaded(true)
    }
  }, [insiderLoaded])

  return (
    <>
      <GatsbySeoCustom noindex nofollow />
      <SearchProvider
        onChange={applySearchState}
        itemsPerPage={ITEM_PER_PAGE_VERIFIED}
        {...searchParams}
      >
        <FilterContextProvider>
          <BreadcrumbJsonLd
            itemListElements={
              serverData?.collection?.breadcrumbList?.itemListElement ?? []
            }
          />
          <div className="page-title__background">
            <div className='"page__section page-title__content'>
              <h1
                className={
                  isLifePage
                    ? 'page-title__text life'
                    : 'page-title__text vivara'
                }
              >
                {newCollectionTitle}
              </h1>
              <p
                className={
                  isLifePage
                    ? 'page-description__text life'
                    : 'page-description__text vivara'
                }
              >
                {description}
              </p>
            </div>
          </div>
          <section className="cms-components__section">
            <RenderCMS sections={beforeSections} fold="above" />
          </section>
          <ProductGallery
            term={null}
            base={base}
            description={serverData?.collection?.seo.description ?? ''}
            title={title}
            cmsHome={cmsHome}
            defaultFacets={defaultFacets}
            sendInsiderEvent
            cmsData={cmsData}
          />
          {afterSections?.length !== 0 && (
            <section className="cms-components__section">
              <RenderCMS sections={afterSections} fold="below" isAfterGallery />
            </section>
          )}
        </FilterContextProvider>
      </SearchProvider>
    </>
  )
}

/**
 * This query is run during SSG
 * */

export const querySSG = graphql`
  query SecondCollectionPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    cmsHome {
      sections {
        name
        data
      }
    }
    allCmsPlp {
      edges {
        node {
          sections {
            name
            data
          }
        }
      }
    }
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export const querySSR = gql`
  query SecondServerCollectionPageQuery($slug: String!) {
    collection(slug: $slug) {
      seo {
        title
        description
      }
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const THIRTY_MINUTES_CACHE = `s-maxage=1800, stale-while-revalidate`

  const errosThowBack = (error: unknown[]) => {
    if (error && error?.length > 0) {
      throw new Error(`${error[0]}`)
    }
  }

  const { data, errors = [] } = await execute({
    operationName: querySSR,
    variables: { slug },
  })

  const notFound = errors.find(isNotFoundError)

  const variables = {
    first: 16,
    after: '0',
    sort: 'score_desc',
    term: '',
    selectedFacets: [
      {
        key: 'colecao',
        value: slug,
      },
    ],
  }

  if (data === null || notFound) {
    const { collectionSearchData, searchErrors } = await getSearchData(
      variables
    )

    errosThowBack(searchErrors)

    if (collectionSearchData?.search?.products?.edges?.length) {
      const jsonData = buildJsonData(slug, variables)

      return {
        status: 200,
        props: jsonData,
        headers: {
          'cache-control': THIRTY_MINUTES_CACHE,
        },
      }
    }

    const params = new URLSearchParams({
      from: encodeURIComponent(`/${slug}`),
    })

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': THIRTY_MINUTES_CACHE,
        location: `/404/?${params.toString()}}`,
      },
    }
  }

  const collectionId = data?.collection?.meta?.selectedFacets?.[0]?.value
  const query = `query {
    collection (id: "${collectionId}") @context(provider: "vtex.catalog-graphql") {
      id
      name
      status
      searchable
      description
    }
  }`

  const { account } = storeConfig

  const { data: dataCollection } = await axios.get(
    `https://${account}.myvtex.com/_v/cms/api/faststore/collectionPage/?filters[config.collectionConfig.collectionId]=${collectionId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  )

  const { secureAccount } = storeConfig
  const { data: collectionDatail }: { data: any } = await axios.post(
    `https://${secureAccount}.com.br/_v/private/graphql/v1`,
    {
      query,
    },
    {
      headers: {
        Accept: 'application/vnd.vtex.ds.v10+json',
        'Content-Type': 'application/json',
      },
    }
  )

  if (!collectionDatail?.data?.collection?.searchable) {
    const { collectionSearchData, searchErrors } = await getSearchData(
      variables
    )

    errosThowBack(searchErrors)

    if (collectionSearchData?.search?.products?.edges?.length) {
      const jsonData = buildJsonData(slug, variables)

      return {
        status: 200,
        props: jsonData,
        headers: {
          'cache-control': THIRTY_MINUTES_CACHE,
        },
      }
    }

    const params = new URLSearchParams({
      from: encodeURIComponent(`/${slug}`),
    })

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': THIRTY_MINUTES_CACHE,
        location: `/404/?${params.toString()}}`,
      },
    }
  }

  if (errors && errors?.length > 0) {
    throw new Error(`${errors[0]}`)
  }

  const description = collectionDatail?.data?.collection?.description ?? ''

  return {
    status: 200,
    props: { ...(data ?? {}), description, dataCollection },
    headers: {
      'cache-control': THIRTY_MINUTES_CACHE,
    },
  }
}

Page.displayName = 'Page'
export default mark(Page)
